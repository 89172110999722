<template>
    <div id="trustpilot-widget">
        <!-- TrustBox widget - Starter -->
        <div id="trustbox" class="trustpilot-widget" data-locale="en-GB" data-template-id="5613c9cde69ddc09340c6beb" data-businessunit-id="50b7a9eb00006400051f090e" data-style-height="100%" data-style-width="100%" data-theme="light">
            <a href="https://uk.trustpilot.com/review/covered2go.co.uk" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
    </div>
</template>

<script>
    export default {
        name: "TrustpilotBanner",
        mounted() {
            const trustboxRef = document.getElementById('trustbox');
            if(window.Trustpilot){
                window.Trustpilot.loadFromElement(trustboxRef);
            }
        }
    }
</script>

<style lang="scss" scoped>
    #trustpilot-widget {
        margin: 0;
        padding: 0;
        padding-top: 10px;
    }
</style>